function onLazyImgLoad(parent) {

    window.setTimeout(function() {

        $(parent).addClass('loaded');
    }, 300);

    this.width = this.naturalWidth;
    this.height = this.naturalHeight;

    parent.appendChild(this);
}

function loadLazyPic() {

    var new_img = new Image();

    new_img.addEventListener('load', onLazyImgLoad.bind(new_img, this));

    new_img.src = this.getAttribute('data-src');
}

$(function() {


    var scrollTrigger = 200,
    backToTop = function () {

        var scrollTop = $(window).scrollTop();
        var copyrightHeight = $('#copyright').innerHeight();
        var copyrightOffset = $('#copyright').offset();
        /*if (scrollTop > scrollTrigger && scrollTop < ($('.page_wrapper').innerHeight() - ($('#copyright').innerHeight() + 155))) {*/
        if (scrollTop > scrollTrigger && scrollTop < copyrightOffset.top - (copyrightHeight + copyrightHeight / 2)) {

            $('.backToTop').addClass('show');
            $('.backToTop').removeClass('absolutePos');
        /*} else if (scrollTop > ($('.page_wrapper').innerHeight() - ($('#copyright').innerHeight() + 155))) {*/
        } else if (scrollTop > copyrightOffset.top - (copyrightHeight + copyrightHeight / 2)) {

            $('.backToTop').addClass('show');
            $('.backToTop').addClass('absolutePos');
        } else {

            $('.backToTop').removeClass('show');
        }
    };

    backToTop();

    $(window).on('scroll', function () {

        backToTop();
    });

    $('.backToTop').on('click', function (e) {

        e.preventDefault();
        $('html, body').animate({

            scrollTop: 0
        }, 700);
    });

    $('.produit_gondole').each(function() {

        child = $(this).children('.menu_tg_holder');

        if (child.length > 0) {

            child.rand(child.length - 1).remove();
            child.removeClass('cache');
        }
    });

    $('#visu_ligne').each(function() {

        child = $(this).children('.menu_collection_pic');

        if (child.length > 0) {

            child.rand(child.length - 1).remove();
            child.removeClass('cache');
        }
    });

    var homeWidth = $('.content_wrapper').width();

    $('.ssmenu').css({ width: homeWidth });

    $(".main_categ").each(function() {

        var obj = $(this);

        obj.mouseenter(function() {

            obj = $(this);

            if (!$(this).hasClass('current')) {

                $(this).addClass('actif');
            }

            if (!obj.hasClass('out')) {

                $('.ssmenu', this).stop(true, true).css({ zIndex: 110 }).fadeIn(300);
            } else {

                $('.ssmenu', this).stop(true, true).css({ zIndex: 110 }).show();
            }

            $('.content_ssmenu', obj).each(function() {

                if ($('div.lazypic', this).length > 0) {

                    if (!$('div.lazypic', this).hasClass('loading')) {

                        $('div.lazypic', this).addClass('loading');

                        loadLazyPic.call($('div.lazypic', this).get(0));
                    }
                }

                $('.ss_cat', this).css({ minHeight: $(this).height() - 20 });
            });
        });

        obj.mouseleave(function() {

            obj = $(this);

            obj.addClass('out');

            if (!$(this).hasClass('current')) {

                $(this).removeClass('actif');
                $(this).css({ borderTop: 'none' });
            }

            $('.ssmenu', this).css({ zIndex: 1 }).fadeOut(300, function() {

                obj.removeClass('out');
            });

            $('.ss_cat', obj).css({ minHeight: '' });
        });
    });

    $('#menu_wrapper, .top_lang, #wrapper_connexion, #cart_top').mouseenter(function() {

        $('.shad_menu').stop(true, true).fadeIn(300);
    });

    $('#menu_wrapper, .top_lang, #wrapper_connexion, #cart_top').mouseleave(function() {

        $('.shad_menu').stop(true, true).fadeOut(300);
    });

    $('.content_ssmenu').each(function() {

        var $obj = $(this),
            $scale = $('.scale_ssmenu', $obj),
            nbrColone = 2;

        for (i = 0; i < nbrColone; i += 1) {

            $scale.prepend('<ul class="f_left content_sscat_left new_ul"></ul>');
        }

        var $oldUl = $('.content_sscat_left', $scale).not('.new_ul'),
            $newUl = $('.content_sscat_left.new_ul', $scale),
            li = $('li.content_sscat', $oldUl),
            nbrLi = $('li', $oldUl).length - 1;

        for (j = 0; j < nbrLi; j += 1) {

            var counter = j % nbrColone,
                detach = li.eq(j);

            $newUl.eq(counter).append(detach);
        }

        $oldUl.remove();
    });

    $('.top_lang').hover(function() {

        $(this).children(".switch_lang").show();
    }, function() {

        $(this).children(".switch_lang").hide();
    });

    $("#connexion_top").hover(function() {

        $(this).children("#wrapper_infos_client").show();
    }, function() {

        if (document.activeElement != undefined) {

            if (document.activeElement.type != 'text' && document.activeElement.type != 'password') {

                $(this).children("#wrapper_infos_client").hide();
            }
        }
    });

    $('.passlost').click(function() {

        $('#connexionTopb .content').animate({ height: $('.forgotPwd').height() }, 600);

        $('.auth').fadeOut(600, function() {

            $('.forgotPwd').fadeIn(600);
        });
    });

    $('#lostpwdTopSucces2 a').click(function() {

        $('#connexionTopb .content').animate({ height: $('.auth').height() }, 600);

        $('.forgotPwd').fadeOut(600, function() {

            $('.auth').fadeIn(600);
        });
    });

    // Emulates placeholder on any input with a data-placeholder attribute
    $(document).on({ focus: checkPlaceholder, blur: checkPlaceholder }, 'input[data-placeholder]');

    try {
        WPApi.prototype.setOption('arrowOffsetLeft', -22);
    } catch (e) {}


});

$(function () {

    "use strict";

    var search = document.getElementById("search_home");

    function onSearchStateChange(evt) {

        if (evt.type === "focus") {

            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {

            search.parentElement.classList.remove("searching");
        }
    }

    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

});